.browse-plans {
  padding: 40px 0;
  width: 121%;
  margin-left: -8%;
  margin-bottom: 60px;
}

.plan-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}
.check-mark {
  color: #27ae60;
}

.p-text {
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 40px;
  color: #666;
}

.plan-cards {
  display: flex;
  justify-content: space-between;
}

.plan-card {
  flex: 1;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin: 0 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

.plan-card:hover {
  transform: translateY(-10px);
}

.basic-plan {
  border: 2px solid #3498db;
}

.premium-plan {
  border: 2px solid #e74c3c;
}

.pro-plan {
  border: 2px solid #27ae60;
}

.plan-card h3 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
}

.plan-card p {
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: #777;
}
.plan-digit {
  font-weight: 700;
}

.price {
  font-size: 2rem;
  font-weight: 500;
  color: #ff5722;
}

.btn-plan {
  background-color: #ff5722;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1.6rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 15px 0px;
}

.btn:hover {
  background-color: #ff7f49;
}

.transparent-div {
  position: relative;
}

.transparent-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  pointer-events: auto;
  z-index: 1;
}
