.userDashboard-h2 {
  text-align: center;
  width: max-content;
}
.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
.profile-img-navbar {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
/*------------------------------input profile image------------------------------*/
.input-profile-image:focus {
  outline: none !important;
}
.input-profile-image::-webkit-file-upload-button {
  visibility: hidden;
}
.input-profile-image::before {
  content: "📤 Choose Image";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 550;
  font-size: 10pt;
}
.input-profile-image:hover::before {
  border-color: #999;
}
.input-profile-image:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
/*--------------------------------------------------------------------------------*/
.user-detail h3 {
  color: white;
}
.user-details {
  width: max-content;
  text-align: start;
}
.Edit-form {
  width: max-content;
  text-align: start;
  max-width: 90%;
  margin: auto;
}
.footer-btn {
  padding: 10px 30px;
  font-size: 20px;
}
.tab-items {
  font-size: 15px !important;
  text-align: left !important;
  padding: 0px 7px !important;
  align-items: flex-start !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #369e0b !important;
}
.css-10d9dml-MuiTabs-indicator {
  background-color: #369e0b !important;
}
.user-profile {
  display: flex;
  gap: 15rem;
  width: 80%;
  margin: auto;
}
.edit-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 5px;
  width: 100%;
}
.payment {
  display: flex;
}
.user-avatar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 100%;
  margin-right: 50px;
}

.dashboard-container {
  width: 100%;
}
.user-profile-card {
  max-width: 690px;
  max-height: 768px;
  margin: auto;
}
.user-card-top {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  margin: 20px;
  align-items: flex-start;
}
.user-detail-data {
  justify-content: flex-start;
  display: flex;
  align-items: center;
}
.detail-svg {
  height: 20px;
  width: 20px;
}
.horizonline {
  height: 50%;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  border: 1px #7e7e7e solid;
}
.user-card-bottom {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  margin: 20px;
}
.vertical {
  border-right: 3px solid #7e7e7e;
}
.used-credit {
  border: 1px solid transparent;
  background: #ffc6c6;
  color: red;
  border-radius: 5px;
  font-size: 25px;
}
.remain-credit {
  border: 2px solid transparent;
  background: #c9ffc5;
  border-radius: 5px;
  font-size: 25px;

  color: #10c700;
}
.user-name-main {
  color: #333;
  font-size: 25px;
  font-weight: bold;
  padding: 0px 5px;
}
@media (max-width: 575px) {
  .user-card-top {
    flex-direction: column;
    align-items: center;
  }
  .user-detail {
    padding-left: 50px;
  }
  .user-card-bottom {
    flex-direction: column;
  }
}
/*---------------Gender-button-margin their class-------------------------*/
.css-qfz70r-MuiFormGroup-root {
  margin: -7px 0 10px 60px !important;
}
/*------------------tab's color hover and select---------------------------*/
.css-7ozjwe {
  background-color: #2d7429 !important;
}
.css-1q2h7u5.Mui-selected {
  color: #2d7429 !important;
}

.cancel-btn {
  background: none;
  border: none;
  text-decoration: underline;
  margin: 10px auto 0 auto;
  font-size: 15px;
}
.editform-footer-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto 0 auto;
  gap: 15px;
}

@media screen and (min-width: 315px) and (max-width: 768px) {
  .tab-items {
    font-size: 12px !important;
  }
}
