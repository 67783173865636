.container-title {
  display: flex;
  align-items: baseline;
  margin-top: 50px;
  margin-bottom: 100px;
}

.btn-chrome-img {
     height: 33px;
    margin: 2.7px 12px 2px -25px;
}
.logo-download {
  margin: 0px 0px 10px 75px;
  height: 160px;
  border-radius: 20px;
  margin-bottom: 30px;
}
.CromeExtension-container-middle h3 {
  margin: 50px 200px;
  color: #777 !important;
}

.Download-extension-btn {
  display: flex;
  gap: 60px;
  justify-content: center;
  margin: 50px 0px;
}
.DownloadButton {
  background: white !important;
  color: #777 !important;
  border: 2px solid #777 !important;
  width: 25% !important;
  border-radius: 50px !important;
  padding: 0px !important;
}

.DownloadButton:hover {
  color: white !important;
  background: linear-gradient(
    to right,
    #297f05f7 0%,
    #3ac500f7 100%
  ) !important;
}
.ChromeStoreLink {
  color: #777 !important;
}

.ChromeStoreLink:hover {
  color: white !important;
  
}

.AddToChromeButton:hover {
  background-color: white !important;
  color: #777 !important;
  border: 2px solid #777 !important;
}
.AddToChromeButton {
  width: 25% !important;
  border-radius: 50px !important;
  padding: 0px !important;
  border: 2px solid transparent !important;
}
.footer-download-section {
  margin-top: 5rem;
}

/*rating Start */

.rating-d {
  font-size: 50px;
}

.star-d {
  cursor: pointer;
}

.star-d::before {
  content: "★";
  font-size: 30px;
  color: #fdd835;
}

@media (max-width: 576px) {
  .CromeExtension-container-middle h3 {
    margin: 0;
    width: 100%;
  }
  .Download-extension-btn {
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0px;
  }
  .DownloadButton {
    width: 88% !important;
    height: 40px !important;
  }

  .btn-chrome-img {
    height: 29px;
    margin: 2px 20px 2px -20px;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .CromeExtension-container-middle h3 {
    margin: 0;
    width: 100%;
  }
  .Download-extension-btn {
    flex-direction: column;
    gap: 20px;
    margin: 20px 0px;
  }
  .DownloadButton {
    width: 100% !important;
  }

  .btn-chrome-img {
    height: 29px;
    margin: 2px 20px 2px -20px;
  }
}


@media screen and (min-width: 769px) and (max-width: 991px) {
  .btn-chrome-img {
    margin: 1px 10px 2px -10px;
  }
.Download-extension-btn {
    gap: 20px;
    margin: 20px 0px;
  }
  .DownloadButton {
    width: 41% !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .btn-chrome-img {
    margin: 1px 10px 2px -10px;
  }

  .DownloadButton {
    width: 32% !important;
  }
}

