.btn-code {
  font-size: 22px;
  padding: 40px 40px 25px 40px;
}
.h3-forget {
  margin: 10px 0px 0px 4px;
  font-size: 17px;
  cursor: pointer;
}
.or {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .btn-code {
    padding: 15px;
  }
}
