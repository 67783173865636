.main-popup .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
        background-color: rgba(0, 0, 0, 0.15);

}


.bootstrap-dialog-title .css-1fdxakk-MuiModal-root-MuiDialog-root .MuiDialogContent-root {
        padding: 0 !important;
}


