.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #2d7429;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container {
  display: flex;
  align-items: center;
  height: 100vh;
  align-items: baseline;
  margin-top: 10rem;
  justify-content: center;
}

/* PaymentSuccess.css */

.payment-success {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: autp;
  margin: 0 auto;
}

.success-icon {
  font-size: 48px;
  color: #32cd32;
  margin-bottom: 10px;
}

.success-heading {
  font-size: 24px;
  color: #333;
  margin: 10px 0;
}

.success-message {
  font-size: 18px;
  color: #666;
  margin: 10px 0;
}

.continue-shopping-btn {
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.continue-shopping-btn:hover {
  background-color: #0056b3;
}
@media (max-width: 576px) {
  .after-loader-container {
    text-align: center;
  }
}
.after-loader-container {
  text-align: center;
}
.tableBody::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.tableBody::-webkit-scrollbar-thumb {
  background-color: #369e0b;
  border-radius: 2px;
}


.record-empty{
      text-align: center;
    font-size: 24px;
    margin: auto;
    margin-top: -15px;
    color: red   ;
}