.singup-heading {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
}
.h3-signup {
  font-size: 15px;
  margin-right: 10px;
}
.input-error {
  color: red;
  margin-bottom: 10px;
  margin-right: 5px;
}
.signup-container {
  gap: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}
.logo {
  box-shadow: 0px 0px 49px 10px rgba(0, 0, 0, 0.72);
  -webkit-box-shadow: 0px 0px 49px 10px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: 0px 0px 49px 10px rgba(0, 0, 0, 0.72);
  height: 160px;
  border-radius: 20px;
  margin-bottom: 30px;
}
.h1-signup {
  line-height: 0;
}
.btn-signup-submit {
  padding: 0;
}
div > input {
  margin-bottom: 10px;
}
.show-hide-btn {
  background: transparent;
  border: none;
  color: inherit;
}
.show-password-button-signup {
  background: transparent;
  border: none;
  position: absolute;
  top: 30px;
  right: 0;
}
