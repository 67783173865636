.mainpopup-right{    
    background: #2E7627;
    color: #fff;
    display: flex;
    flex-direction:column !important;
    align-items: center;
    justify-content:center;
    text-align:center;
    padding: 90px 10px;
    margin: 0;
}
.container-mainpopup{
    display: flex;
    align-items: center;
    justify-content:center;
    @media (max-width:768px) {
        display: block;
    }
}
.close-icn{
   @media (max-width:768px){
color: black;
       fill: black;
   }
}

.btn-mainpop{
    display: inline-flex;
padding: 8px 12px;
justify-content: center;
align-items: center;
gap: 14px;
outline: none;
border-radius: 5px;
background: #FFF;
color: #2E7627;
box-shadow: -2px 2px 6px 0px rgba(0, 0, 0, 0.25), 2px -2px 6px 0px rgba(0, 0, 0, 0.25);
}



.btn-mainpop {
  --border-size: 3px;
  --border-angle: 0turn;
  
  background-image: conic-gradient(
      from var(--border-angle),
      #fff,
      #fff 50%,
      #fff
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03, #2E7627);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
}

.btn-mainpop:hover {
  color:white;
  background-image: conic-gradient(
      from var(--border-angle),
      #2E7627,
      #2E7627 50%,
      #2E7627
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03, #2E7627);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

/* Custom property definition */
@media (prefers-reduced-motion: reduce) {
  .btn-mainpop {
    animation: none;
  }
}

/* Custom property definition */
:root {
  --border-angle: 0turn;
}

/* Custom property definition */
@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
