.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.or-p {
  font-size: 17px;
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
}
.login-form {
  background-color: #fff;
  padding: 40px;
  margin: auto;
  height: inherit;
  width: 100%;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 0;
}

.label-login {
  font-weight: bold;
}

.input-login {
  width: 100%;
  outline: none;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
    margin-bottom: 3px;

}
.input-login-password {
  padding: 10px 45px 10px 10px;
  margin-bottom: 3px;
}

.btn-login-submit {
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: 15px;
  padding: 7px 20px;
  margin-top: 10px;
}
img {
  height: 60px;
  margin: 0px 5px;
}
.forget-pass {
  margin: 3px 0px;
  text-align: right;
  color: #777;
}
    .link-signup {
  margin: 15px 0px;
  display: flex;
}
.btn-signup {
  color: green;
  text-decoration: underline;
}
.h3-login {
  font-size: 17px;
  margin: 10px 15px 0px 0px;
}

.pass-forget-show-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

/* feedback-form-rating*/

.rating-container {
  text-align: center;
}

.rating {
  font-size: 30px;
}

.star {
  cursor: pointer;
}

.star::before {
  content: "★";
  font-size: 30px;
  color: #ccc;
}

.star.filled::before {
  color: #fdd835;
}
.input-error {
  text-align: left;
}
.feedback-text {
  margin-bottom: 15px;
  text-align: left;
  font-size: 1.5rem;
  color: #666;
}
.feedback-containers {
  max-width: 90%;
  width: 100%;
  margin-left: 10rem;
}
.show-password-button {
  background: transparent;
  border: none;
  position: absolute;
  top: 30px;
  right: 0;
}

.login-main-container {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  max-width: 80%;
}
.vector-top {
  position: absolute;
  height: 250px;
  top: 0px;
  left: 0;
}
.vector-bottom {
  position: absolute;
  height: 250px;
  bottom: 0px;
  left: 0px;
}
.login-left {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

@media (max-width: 576px) {
  .feedback-containers {
    margin: 0 !important;
  }
  .star::before {
    font-size: 35px;
  }
  .login-main-container {
    justify-content: center;
    max-width: 100%;
  }
  .login-form {
    padding: 40px 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .login-main-container {
    max-width: 85% !important;
  }
}

