.input-verify {
  display: flex;
  justify-content: center;
  font-size: 30px;
}
.form-label {
  margin: 15px 100px;
  font-size: 15px;
  float: none;
}
.form-verify {
  max-width: 600px;
}
.input-verify::after .input-verify:hover .input-verify:active {
  border: none;
}
.btn-verify {
  padding: 10px 50px;
}
/* OTPInput.css */

.otp-inputs {
  display: flex;
  justify-content: center;
  gap: 10px;
  text-align: center;
}

.otp-input {
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.input-error-center {
  text-align: center;
}
.input-verify:active {
  border: none;
  outline: none;
}
